<template>
  <div class="invite-container">
    <div class="invite-input-wrapper">
      <div class="invite-input-group">
        <div class="invite-input-container"
             :class="{'focused': focused, 'unfocused': !focused}"
             @click.stop="containerOnClick"
        >
          <div v-for="bubble of bubbles" :key="bubble" class="bubble-row">
            <div class="bubble">
              <span class="bubble-text" :title="bubble">{{ formatBubbleEmail(bubble) }}</span>
              <div class="bubble-remove-icon" @click="deleteBubble(bubble)">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="2.05017" y="3.46436" width="2" height="12" rx="1" transform="rotate(-45 2.05017 3.46436)" fill="white"/>
                  <rect x="3.46423" y="11.9497" width="2" height="12" rx="1" transform="rotate(-135 3.46423 11.9497)" fill="white"/>
                </svg>
              </div>
            </div>
          </div>
          <input
              ref="inviteInput"
              :size="inputValue.length < 4 ? 4 : inputValue.length"
              tabindex="-1"
              type="email"
              :placeholder="bubbles.length === 0 ? 'Email address' : ''"
              class="invite-input bg-gray-100"
              :class="{'full-width': fullInputWidth}"
              v-model="inputValue"
              @keydown.stop.delete="deleteLastBubble"
              @keydown.stop.enter="proceedBubbles"
              @keydown.stop.space="proceedBubbles"
              @keydown.stop.188="proceedBubbles"
              @blur="proceedBubbles"
              @focusin="focused = true"
              @focusout="focused = false"
          >
        </div>
        <div class="invite-action-buttons">
          <PermissionSelector
              class="invite-permission"
              text-align="left"
              :value="roleUser"
              @input="setUserRole"
              v-model="permission"
          />
          <button
              tabindex="-1"
              class="invite-button"
              @click.stop="sendInvite"
          >
            Send invite
          </button>
        </div>
      </div>
    </div>

    <div v-if="hasWrongEmail" class="wrong-email">Invalid email address</div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import PermissionSelector from "./PermissionSelector.vue";

const emailRegExp = new RegExp(
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z]{2,})+$"
);
export default {
  name: "InviteParticipants",
  components: {PermissionSelector},
  props: ['projectId'],
  setup(props,context) {
    const permission = ref(-1),
          inputValue = ref(''),
          roleUser = ref("VIEWER"),
          bubbles = ref([]),
          focused = ref(false),
          hasWrongEmail = ref(false),
          inviteInput = ref(null)

    const fullInputWidth = computed(()=> {
      return bubbles.value.length === 0
    })
    function formatBubbleEmail(email = '') {
      const maxLength = 25
      return email.length > maxLength
          ? email.slice(0, maxLength) + '...'
          : email
    }
    function setUserRole(roleId) {
      roleUser.value = roleId
    }
    function deleteBubble(bubble) {
      bubbles.value = bubbles.value.filter(email => email !== bubble)
    }
    function deleteLastBubble(e) {
      hasWrongEmail.value = false
      if (e.target.value) return
      e.preventDefault()
      const arr = Array.from(bubbles.value)
      arr.pop()
      bubbles.value = arr
    }
    function proceedBubbles(e) {
      e.preventDefault()
      const emailsString = inputValue.value
          .replace(/\s+/g, ',')
          .replace(/,+/g, ',')
      const emailsArray = emailsString.split(',').filter(email => !!email)
      hasWrongEmail.value = emailsArray.some(email => !emailRegExp.test(email))
      if (hasWrongEmail.value) return

      const emailsSet = new Set([...bubbles.value, ...emailsArray])
      bubbles.value = Array.from(emailsSet)
      inputValue.value = ''
    }
    function sendInvite() {
      // if (this.bubbles.length === 0) return
      // if (!this.project) return
      // const alreadyInvitedUsers =  Object.keys(this.project.perms).map(email => `${email}`.toLowerCase())
      // if (this.bubbles.some(email => alreadyInvitedUsers.includes(email.toLowerCase()))) {
      //   return this.showAlert('An invite has already been sent to this email address.')
      // }
      // this.$emit('sendInvite', {
      //   emails: this.bubbles,
      //   permission: this.permission
      // })
      context.emit('sendInvite',{
        emails:bubbles.value,
        permission: roleUser.value,
      })
      inputValue.value = ''
      bubbles.value = []
    }
    function  containerOnClick() {
      if (inviteInput.value) return
      inviteInput.value.focus()
    }
    return {
      permission,
      setUserRole,
      inputValue,
      bubbles,
      focused,
      roleUser,
      hasWrongEmail,
      fullInputWidth,
      deleteLastBubble,
      formatBubbleEmail,
      containerOnClick,
      sendInvite,
      proceedBubbles,
      deleteBubble,
      inviteInput,
    }
  },
}
</script>

<style scoped lang="scss">
.invite-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  width: 100%;

  .invite-input-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .invite-input-group {
    display: flex;
    width: 100%;
    align-items: flex-start;
  }

  .invite-input-container {
    border: solid 1px #FFFFFF;
    -webkit-box-flex: 1;
    flex-grow: 1;
    border-radius: 6px;
    padding: 0.8px;
    gap: 5px;
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    &.focused {
      border: solid 1px #539af5;
      transition: border-color 200ms ease-in-out;
    }
    &.unfocused {
      border: solid 1px silver;
      transition: border-color 200ms ease-in-out;
    }
    .bubble-row {
      display: inline-block;
      margin: 1px;

      .bubble {
        display: flex;
        align-items: center;
        flex-direction: row;
        height: 24px;
        background: rgba(197, 200, 202, 0.5);
        border-radius: 15px;
        padding: 0 5px 0 8px;
        cursor: default;

        &:hover {
          background: rgba(197, 200, 202, 1);
        }

        .bubble-text {
          //border: solid 1px lime;
          display: inline-block;
          margin-right: 5px;
          font-weight: 400;
          font-size: 14px;
          color: #343541;
          white-space: nowrap;
          overflow: hidden;
        }
        .bubble-remove-icon {
          display: flex;
          cursor: pointer;
        }
      }
    }

    .invite-input {
      // max-width: 250px;
      outline: none;
      border: none;
      background: transparent;

      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #334155;
      font-family: inherit;
      padding-left: 2px;

      flex-grow: 1;
    }

    .invite-input:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }

  .invite-input-container > :first-child {
    margin-left: 8px;
  }

  .invite-input-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: solid 1px silver;
    border-radius: 6px;
    min-height: 36px;
    width: 100%;

    &.focused {
      border: solid 1px #539af5;
      transition: border-color 200ms ease-in-out;
    }
  }

  .invite-action-buttons {
    display: flex;
    align-items: center;
    width: 50%;
    white-space: nowrap;
  }

  .invite-permission {
    border: solid 1px silver;
    margin: 0 4px;
    border-radius: 6px;
    min-width: 119px;
    text-align: center;
    width: 50%;
    max-width: 50%;
    display: flex;
    align-items: center;
    position: relative;
  }

  .invite-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 132px;
    height: 39px;
    min-width: 132px;
    border-style:none;
    background: #334155;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;

    &:active {
      transform: translate(1px, 1px);
    }
  }

  .wrong-email {
    font-weight: 400;
    font-size: 11px;
    margin-top: 5px;
    color: #EB3131;
  }
}
</style>
