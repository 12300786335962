import {
  FONT_FAMILY,
  FONT_WEIGHT,
  TEXT_ALIGNMENT,
} from "../../core/constants.js";

export const ALLOWED_CONTENT_TYPES = [
  "application/pdf",
  "image/png",
  "image/jpg",
  "image/jpeg",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-word.document.macroEnabled.12",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  "application/vnd.ms-word.template.macroEnabled.12",
  "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  "application/vnd.openxmlformats-officedocument.presentationml.template",
  "application/vnd.ms-powerpoint.template.macroEnabled.12",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  "text/csv",
  "application/vnd.oasis.opendocument.presentation",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.oasis.opendocument.text",
  "text/rtf"
];

export const ICON_BY_TYPE = {
  default: {
    title: "-",
    className: "default",
  },
  "application/pdf": {
    title: "PDF",
    className: "pdf",
  },
  "image/png": {
    title: "PNG",
    className: "png",
  },
  "image/jpg": {
    title: "JPG",
    className: "jpg",
  },
  "image/jpeg": {
    title: "JPG",
    className: "jpg",
  },
  "application/msword": {
    title: "DOC",
    className: "doc",
  },
  "application/vnd.ms-excel": {
    title: "XLS",
    className: "xls",
  },"text/csv": {
    title: "CSV",
    className: "xls",
  },
  "application/vnd.ms-powerpoint": {
    title: "PPT",
    className: "ppt",
  },
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": {
    title: "DOCX",
    className: "docx",
  },
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": {
    title: "XLSX",
    className: "xlsx",
  },
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": {
    title: "PPTX",
    className: "pptx",
  },
  "application/vnd.ms-word.document.macroEnabled.12": {
    title: "DOC",
    className: "doc",
  },
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template": {
    title: "XLSX",
    className: "xlsx",
  },
  "application/vnd.ms-word.template.macroEnabled.12": {
    title: "DOC",
    className: "doc",
  },
  "application/vnd.ms-powerpoint.presentation.macroEnabled.12": {
    title: "PPT",
    className: "ppt",
  },
  "application/vnd.openxmlformats-officedocument.presentationml.template": {
    title: "PPTX",
    className: "pptx",
  },
  "application/vnd.ms-powerpoint.template.macroEnabled.12": {
    title: "PPT",
    className: "ppt",
  },
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow": {
    title: "PPTX",
    className: "pptx",
  },
  "application/vnd.ms-powerpoint.slideshow.macroEnabled.12": {
    title: "PPT",
    className: "ppt",
  },
  "application/vnd.google-apps.spreadsheet": {
    title: "GSHEET",
    className: "gsheet",
  },
  "application/vnd.google-apps.document": {
    title: "GDOC",
    className: "gdoc",
  },
  "application/vnd.google-apps.presentation": {
    title: "GSLIDE",
    className: "gslide",
  },
  "image/svg+xml": {
    title: "SVG",
    className: "svg",
  },
};

export const REPRESENTATION = {
  HOME_TABS: {
    ALL: "ALL",
    PRIVATE: "PRIVATE",
    SHARED: "SHARED",
  },
  VIEWS: {
    GRID: "GRID",
    TABLE: "TABLE",
  },
  SORT: {
    DATE_ADDED: "DATE_ADDED",
    LAST_MODIFIED: "LAST_MODIFIED",
    NAME: "NAME",
  },
  DIRECTIONS: {
    DESCENT: "DESCENT",
    ASCENT: "ASCENT",
  },
  DEFAULT: {
    VIEW: "GRID",
    SORT: "LAST_MODIFIED",
    DIRECTION: "ASCENT",
    HOME_TAB: "ALL",
  },

  SORT_FUNC: {
    DATE_ADDED: {
      ASCENT: (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt),
      DESCENT: (a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt),
    },
    LAST_MODIFIED: {
      ASCENT: (a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt),
      DESCENT: (a, b) => Date.parse(a.updatedAt) - Date.parse(b.updatedAt),
    },
    NAME: {
      ASCENT: (a, b) => a.name.localeCompare(b.name),
      DESCENT: (a, b) => b.name.localeCompare(a.name),
    },
  },
};

export const SORT = {
  TYPES: {
    MODIFIED: "LAST MODIFIED",
    ADDED: "DATE ADDED",
    NAME: "NAME",
  },
  DIRECTIONS: {
    DESCENT: "DESCENT",
    ASCENT: "ASCENT",
  },
};

export const SHARING_STATUS = {
  PUBLIC: "PUBLIC",
  PRIVATE: "PRIVATE",
};

export const ICON = {
  PLUS: "PLUS",
  EXPAND: "EXPAND",
  UNDO: "UNDO",
  REDO: "REDO",
  DELETE: "DELETE",
  REVERT: "REVERT",
  DUPLICATE: "DUPLICATE",
  INFO: "INFO",
  CLOSE: "CLOSE",
  SELECT: "SELECT",
  POINTER: "POINTER",
  INK: "INK",
  SHAPE: "SHAPE",
  CIRCLE: "CIRCLE",
  SQUARE: "SQUARE",
  ARROW: "ARROW",
  ERASERS: "ERASERS",
  OBJECT_ERASER: "OBJECT_ERASER",
  PIXEL_ERASER: "PIXEL_ERASER",
  MARKER: "MARKER",
  UNDERLINE: "UNDERLINE",
  STRIKEOUT: "STRIKEOUT",
  TEXT_BOX: "TEXT_BOX",
  IMAGE: "IMAGE",
  COMMENT: "COMMENT",
  REPLY: "REPLY",
  STAMP: "STAMP",
  BOOKMARK: "BOOKMARK",
  BOLD: "BOLD",
  ALIGN_LEFT: "ALIGN_LEFT",
  ALIGN_CENTER: "ALIGN_CENTER",
  ALIGN_RIGHT: "ALIGN_RIGHT",
};

// tools
export const TOOL = {
  INK: {
    name: "INK",
    title: "Pencil",
    icon: ICON.INK,
    presets: {
      name: "inkPresets",
      list: [
        {
          index: 0,
          color: "#EF4444FF",
          lineWidth: 2,
          addedAt: "2023-08-01T18:52:52.187Z",
        },
        {
          index: 1,
          color: "#0EA5E9FF",
          lineWidth: 6,
          addedAt: "2023-08-01T18:52:52.187Z",
        },
        {
          index: 2,
          color: "#000000FF",
          lineWidth: 10,
          addedAt: "2023-08-01T18:52:52.187Z",
        },
      ],
      min: 3,
      max: 10,
      template: { color: "#EF4444FF", lineWidth: 2 },
    },
  },
  MARKER: {
    name: "MARKER",
    title: "Marker",
    icon: ICON.MARKER,
    presets: {
      name: "markerPresets",
      list: [
        { index: 0, color: "#FFED69FF", addedAt: "2023-08-01T18:52:52.187Z" },
        { index: 1, color: "#D3E660FF", addedAt: "2023-08-01T18:52:52.187Z" },
        { index: 2, color: "#22D3EEFF", addedAt: "2023-08-01T18:52:52.187Z" },
      ],
      min: 3,
      max: 10,
      template: { color: "#EF4444FF" },
    },
  },
  UNDERLINE: {
    name: "UNDERLINE",
    title: "Underline",
    icon: ICON.UNDERLINE,
    presets: {
      name: "underlinePresets",
      list: [
        { index: 0, color: "#EF4444FF", addedAt: "2023-08-01T18:52:52.187Z" },
      ],
      min: 1,
      max: 1,
    },
  },
  CROSSLINE: {
    name: "CROSSLINE",
    title: "Crossline",
    icon: ICON.STRIKEOUT,
    presets: {
      name: "crosslinePresets",
      list: [
        { index: 0, color: "#000000FF", addedAt: "2023-08-01T18:52:52.187Z" },
      ],
      min: 1,
      max: 1,
    },
  },
  TEXT_BOX: {
    name: "TEXT_BOX",
    title: "Text",
    icon: ICON.TEXT_BOX,
    presets: {
      name: "textBoxPresets",
      list: [
        {
          index: 0,
          color: "#000000FF",
          fontFamily: FONT_FAMILY.SANS_SERIF,
          fontSize: 14,
          fontWeight: FONT_WEIGHT.W400,
          textAlignment: TEXT_ALIGNMENT.START,
          addedAt: "2023-08-01T18:52:52.187Z",
        },
      ],
      min: 1,
      max: 1,
    },
  },
  CIRCLE: {
    name: "CIRCLE",
    title: "Circle",
    icon: ICON.CIRCLE,
    presets: {
      name: "circlePresets",
      list: [
        {
          index: 0,
          color: "#EF4444FF",
          lineWidth: 2,
          addedAt: "2023-08-01T18:52:52.187Z",
        },
        {
          index: 1,
          color: "#0EA5E9FF",
          lineWidth: 6,
          addedAt: "2023-08-01T18:52:52.187Z",
        },
        {
          index: 2,
          color: "#000000FF",
          lineWidth: 10,
          addedAt: "2023-08-01T18:52:52.187Z",
        },
      ],
      min: 3,
      max: 10,
      template: { color: "#EF4444FF", lineWidth: 2 },
    },
  },
  SQUARE: {
    name: "SQUARE",
    title: "Square",
    icon: ICON.SQUARE,
    presets: {
      name: "squarePresets",
      list: [
        {
          index: 0,
          color: "#EF4444FF",
          lineWidth: 2,
          addedAt: "2023-08-01T18:52:52.187Z",
        },
        {
          index: 1,
          color: "#0EA5E9FF",
          lineWidth: 6,
          addedAt: "2023-08-01T18:52:52.187Z",
        },
        {
          index: 2,
          color: "#000000FF",
          lineWidth: 10,
          addedAt: "2023-08-01T18:52:52.187Z",
        },
      ],
      min: 3,
      max: 10,
      template: { color: "#EF4444FF", lineWidth: 2 },
    },
  },
  ARROW: {
    name: "ARROW",
    title: "Arrow",
    icon: ICON.ARROW,
    presets: {
      name: "arrowPresets",
      list: [
        {
          index: 0,
          color: "#EF4444FF",
          lineWidth: 2,
          addedAt: "2023-08-01T18:52:52.187Z",
        },
        {
          index: 1,
          color: "#0EA5E9FF",
          lineWidth: 6,
          addedAt: "2023-08-01T18:52:52.187Z",
        },
        {
          index: 2,
          color: "#000000FF",
          lineWidth: 10,
          addedAt: "2023-08-01T18:52:52.187Z",
        },
      ],
      min: 3,
      max: 10,
      template: { color: "#EF4444FF", lineWidth: 2 },
    },
  },

  COMMENT: {
    name: "COMMENT",
    title: "Comment",
    icon: ICON.COMMENT,
    disabled: true,
  },
  REPLY: {
    name: "REPLY",
    title: "Reply",
    icon: ICON.REPLY,
    disabled: true,
  },
  BOOKMARK: {
    name: "BOOKMARK",
    title: "Bookmark",
    icon: ICON.BOOKMARK,
    disabled: true,
  },
  ERASER: {
    name: "ERASER",
    title: "Object eraser",
    icon: ICON.OBJECT_ERASER,
    presets: {
      name: "__eraserPresets",
      list: [],
      template: { lineWidth: 10 },
    },
  },
  PIXEL_ERASER: {
    name: "PIXEL_ERASER",
    title: "Pixel eraser",
    icon: ICON.PIXEL_ERASER,
    presets: {
      name: "eraserPresets",
      list: [{ index: 0, lineWidth: 10 }],
      template: { lineWidth: 1 },
    },
  },
  STAMPS: {
    name: "STAMPS",
    title: "Stamps",
    icon: ICON.STAMP,
    disabled: false,
  },
  IMAGE: { name: "IMAGE", title: "Image", icon: ICON.IMAGE },
};

export const CUSTOM_TOOLS = {
  FONT_WEIGHT: {
    name: "BOLD",
    title: "Bold",
    shortcut: "",
    icon: ICON.BOLD,
    disabled: false,
  },
  TEXT_ALIGNMENT_LEFT: {
    name: "ALIGN_LEFT",
    title: "Left aligned",
    shortcut: "",
    icon: ICON.ALIGN_LEFT,
    disabled: false,
  },
  TEXT_ALIGNMENT_CENTER: {
    name: "ALIGN_CENTER",
    title: "Center aligned",
    shortcut: "",
    icon: ICON.ALIGN_CENTER,
    disabled: false,
  },
  TEXT_ALIGNMENT_RIGHT: {
    name: "ALIGN_RIGHT",
    title: "Right aligned",
    shortcut: "",
    icon: ICON.ALIGN_RIGHT,
    disabled: false,
  },
};
export const UNDO = {
  title: "Undo",
  shortcut: navigator.platform.indexOf("Mac") === 0 ? "⌘Z" : "Ctrl + Z",
  icon: ICON.UNDO,
};
export const REDO = {
  title: "Redo",
  shortcut:
    navigator.platform.indexOf("Mac") === 0 ? "⇧⌘Z" : "Ctrl + Shift + Z",
  icon: ICON.REDO,
};
export const NO_SELECTED_TOOL = {
  name: "SELECT",
  title: "Select",
  shortcut: "A",
  icon: ICON.SELECT,
};
export const TOOL_GROUPS = {
  PENCIL: {
    name: "PENCIL",
    title: "Pencil",
    shortcut: "P",
    icon: ICON.INK,
    tools: [TOOL.INK],
  },
  HIGHLIGHT: {
    name: "HIGHLIGHT",
    title: "Highlighter",
    shortcut: "H",
    icon: ICON.MARKER,
    tools: [TOOL.MARKER, TOOL.UNDERLINE, TOOL.CROSSLINE],
  },
  COMMENT: {
    name: "COMMENT",
    title: "Comment",
    shortcut: "C",
    icon: ICON.COMMENT,
    tools: [TOOL.COMMENT],
    disabled: false,
  },
  STAMPS: {
    name: "STAMPS",
    title: "Stamp",
    shortcut: "S",
    icon: ICON.STAMP,
    tools: [TOOL.STAMPS],
    disabled: false,
  },
  SHAPE: {
    name: "SHAPE",
    title: "Shape",
    shortcut: "U",
    icon: ICON.SHAPE,
    tools: [TOOL.SQUARE, TOOL.CIRCLE, TOOL.ARROW],
  },
  TEXT_BOX: {
    name: "TEXT_BOX",
    title: "Text",
    shortcut: "T",
    icon: ICON.TEXT_BOX,
    tools: [TOOL.TEXT_BOX],
  },
  IMAGE: {
    name: "IMAGE",
    title: "Image",
    shortcut: "I",
    icon: ICON.IMAGE,
    tools: [TOOL.IMAGE],
  },
  ERASERS: {
    name: "ERASERS",
    title: "Erasers",
    shortcut: "E",
    icon: ICON.ERASERS,
    tools: [TOOL.ERASER, TOOL.PIXEL_ERASER],
  },
};

export const MIN_LINE_WIDTH = 1;
export const MAX_LINE_WIDTH = 20;

export const TOOL_PRESETS = {
  [TOOL.INK.name]: {
    presets: [
      { color: "#ff6600ff", lineWidth: 5 },
      { color: "#44ff00ff", lineWidth: 10 },
      { color: "#0048ffff", lineWidth: 20 },
    ],
    usedIndex: 0,
  },
  [TOOL.CIRCLE.name]: {
    presets: [
      { color: "#ff6600ff", lineWidth: 5 },
      { color: "#44ff00ff", lineWidth: 10 },
      { color: "#0048ffff", lineWidth: 20 },
    ],
    usedIndex: 0,
  },
  [TOOL.SQUARE.name]: {
    presets: [
      { color: "#ff6600ff", lineWidth: 5 },
      { color: "#44ff00ff", lineWidth: 10 },
      { color: "#0048ffff", lineWidth: 20 },
    ],
    usedIndex: 0,
  },
  [TOOL.ARROW.name]: {
    presets: [
      { color: "#ff6600ff", lineWidth: 5 },
      { color: "#44ff00ff", lineWidth: 10 },
      { color: "#0048ffff", lineWidth: 20 },
    ],
    usedIndex: 0,
  },
  [TOOL.MARKER.name]: {
    presets: [
      { color: "#ff6600ff" },
      { color: "#44ff00ff" },
      { color: "#0048ffff" },
    ],
    usedIndex: 0,
  },
  [TOOL.UNDERLINE.name]: {
    presets: [
      { color: "#ff6600ff" },
      { color: "#44ff00ff" },
      { color: "#0048ffff" },
    ],
    usedIndex: 0,
  },
  [TOOL.CROSSLINE.name]: {
    presets: [
      { color: "#ff6600ff" },
      { color: "#44ff00ff" },
      { color: "#0048ffff" },
    ],
    usedIndex: 0,
  },
  [TOOL.TEXT_BOX.name]: {
    presets: [
      {
        color: "#4a4a4aff",
        fontFamily: FONT_FAMILY.MONOSPACE,
        fontSize: 21,
        fontWeight: FONT_WEIGHT.W400,
        textAlignment: TEXT_ALIGNMENT.START,
      },
    ],
    usedIndex: 0,
  },
  [TOOL.ERASER.name]: {
    presets: [],
    usedIndex: 0,
  },
  [TOOL.PIXEL_ERASER.name]: {
    presets: [{ lineWidth: 5 }],
    usedIndex: 0,
  },
};
