<script>
import { inject, reactive, ref, computed, onMounted, onBeforeUnmount, watch } from "vue";
import AppBarVue from "./app-bar/app-bar.vue";
import UploadMonitor from "./common/UploadMonitor.vue";
import { useRoute } from "vue-router";
import PublicLinkAccessBanner from "./PublicLinkAccessBanner.vue";
import { getters, mutations } from "../store/index.js";
import leftSidePanelState from "../store/left-side-panel-state.js";
import LeftSidePanel from "./common/left-side-panel/LeftSidePanel.vue";

export default {
  name: "ApplicationView",
  components: { LeftSidePanel, PublicLinkAccessBanner, AppBarVue, UploadMonitor },
  setup() {
    const $fetch = inject("$fetch");
    const route = useRoute();
    const shortPanelEl = ref(null);
    const highPanelEl = ref(null);
    const appViewEl = ref(null);
    const state = reactive({
      shortLeftPanelShown: false,
      highLeftPanelPinned: false,
    });
    const uploadMonitorShown = computed(() => {
      return getters.uploadMonitorShown;
    });
    const leftPanelCanBeOpened = computed(() => {
      if (!["project", "document", "global-search"].includes(route.name)) return false;
      if (route.name === "global-search") return !!leftSidePanelState.fromProject;
      return true;
    });
    const isPanelPinned = computed(() => {
      // console.log(leftPanelCanBeOpened.value, leftSidePanelState.isPanelPinned);
      return leftPanelCanBeOpened.value && leftSidePanelState.isPanelPinned;
    });
    let mouseTimeout;

    onMounted(() => {
      document.querySelectorAll("#splash-screen").forEach(el => el.remove());
      $fetch.version().then(ver =>{ 
        // console.info(ver)
      });
      window.addEventListener("click", windowOnClick);
      window.addEventListener("mousemove", onMouseMove);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("click", windowOnClick);
      window.removeEventListener("mousemove", onMouseMove);
    });
    watch(() => route.name, (routeName) => {
      if (!["project", "document", "global-search"].includes(routeName)) {
        state.shortLeftPanelShown = false;
      };
    });
    watch(() => state.shortLeftPanelShown, (shown) => {
      if (!shortPanelEl) return;
      if (shown) {
        shortPanelEl.value.classList.toggle("shown", true);
        shortPanelEl.value.classList.toggle("hidden", false);
      } else {
        shortPanelEl.value.classList.toggle("shown", false);
        shortPanelEl.value.classList.toggle("hidden", true);
      }
    })

    function hideUploadMonitor() {
      mutations.toggleUploadMonitorShown(false);
    }
    function windowOnClick(e) {
      if (e.target.tagName === "FOLIA-PAGE") return;
      window.foliaPdfViewer?.eventBus?.dispatch("reset-objects-selection");
    }
    function onMouseMove(e) {
      if (!("projectId" in route.params)) return;
      if (leftSidePanelState.isPanelPinned) return;

      let { left, top, right, bottom } = shortPanelEl?.value?.getBoundingClientRect()??{};
      clearTimeout(mouseTimeout);
      if (state.shortLeftPanelShown === false) {
        if (e.x <= 20 && e.y >= top && e.y <= bottom) state.shortLeftPanelShown = true;
      } else {
        mouseTimeout = setTimeout(() => {
          const isInsidePanel = e.x >= left && e.y >= top && e.x <= right && e.y <= bottom;
          if (!isInsidePanel) state.shortLeftPanelShown = false;
        }, 10);
      }

    }
    function unpinLeftSidePanel() {
      leftSidePanelState.unpinLeftSidePanel();
    }
    function pinLeftSidePanel() {
      leftSidePanelState.pinLeftSidePanel();
    }

    return {
      isPanelPinned, shortPanelEl, highPanelEl, appViewEl, state, route,
      uploadMonitorShown, leftPanelCanBeOpened,
      hideUploadMonitor, unpinLeftSidePanel, pinLeftSidePanel,
    };
  },
};
</script>
<style lang="scss">
.panel-header-logo {
  width: 100px;
  height: 51px;
  cursor: pointer;
  background-image: url("../assets/images/logo.svg");
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 56px 28px;
}

</style>

<template>
  <div class="flex min-h-full">
    <!-- Left Panel -->
    <div v-show="isPanelPinned" ref="highPanelEl" class="high-side-bar-container transition-all duration-300 border-r z-20 ml-4" :class="isPanelPinned ? 'w-60' : 'w-0'">
      <div class="panel-header flex mt-2">
        <div class="panel-header-logo " @click="$router.push({ name: 'home' })"></div>
        <folia-button class="ml-24 mt-2" size="28" icon="close_side_panel" tooltip="Unlock Left Panel" selected
          @click.stop="unpinLeftSidePanel"></folia-button>
      </div>
      <LeftSidePanel />
    </div>

    <!-- Main Content Area -->
    <div class="flex-1 transition-all duration-[3s]">
      <AppBarVue :leftPanelCanBeOpened="leftPanelCanBeOpened" :isPanelPinned="isPanelPinned"
        @pinLeftSidePanel="pinLeftSidePanel()" />
      <div class="mx-auto bg-gray-50 transition-all duration-300">
        <router-view :key="route.fullPath"></router-view>
      </div>
      <UploadMonitor @hide="hideUploadMonitor" v-if="uploadMonitorShown" />
    </div>
  </div>
</template>
