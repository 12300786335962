<template>
    <div v-if="isAndroid && showBanner" class="install-banner">
      <p>Get our mobile app!</p>
      <button @click="installApp">Install</button>
      <button class="close-btn" @click="dismissBanner">&times;</button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isAndroid: false,
        showBanner: true,
        playStoreLink: "https://play.google.com/store/apps/details?id=com.folia.app",
      };
    },
    mounted() {
      this.detectAndroid();
    },
    methods: {
      detectAndroid() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
          this.isAndroid = true;
        } else {
          if (navigator.userAgentData) {
            const brands = navigator.userAgentData.getHighEntropyValues(["platform"]);
            if (brands) {
              brands.then((data) => {
              if (data.platform === "Linux") { // This is the platform value for Samsung Android
                this.isAndroid = true;
              }
            });
            }
          }
        }
      },
      installApp() {
        window.location.href = this.playStoreLink;
      },
      dismissBanner() {
        this.showBanner = false;
        localStorage.setItem("install-banner-dismissed", "true"); // Remember dismissal
      },
    },
  };
  </script>
  
  <style scoped>
  .install-banner {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    gap: 10px;
    z-index: 1000;
  }
  
  button {
    background: white;
    color: #007bff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button.close-btn {
    background: none;
    color: white;
    font-size: 18px;
  }
  </style>