import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import auth0Plugin from "./plugins/auth0-plugin.js";
import fetchWrapper from "./plugins/fetch-wrapper.js";
import uploadFilesPlugin from "./plugins/upload-files.js";
import { plugin as unleashPlugin } from "@unleash/proxy-client-vue";
import { BroadcastChannelNetworkAdapter } from "@automerge/automerge-repo-network-broadcastchannel";
import { IndexedDBStorageAdapter } from "@automerge/automerge-repo-storage-indexeddb";
import { FoliaBrowserWebsocketClientAdaptor } from "./plugins/folia-automerge-adaptor.ts";

import { Repo } from "@automerge/automerge-repo";
const {
  VITE_UNLEASH_URL: url,
  VITE_UNLEASH_CLIENT_KEY: clientKey,
  VITE_UNLEASH_APP_NAME: appName,
  VITE_AUTOMERGE_SYNC_SERVER: automergeUrl,
} = import.meta.env;
const config = {
  url: url,
  clientKey: clientKey,
  refreshInterval: 15,
  appName: appName,
};
import Store from "./store/index.js";
import * as Sentry from "@sentry/vue";
import VueGtag from "vue-gtag";
import ClickOutside from "./plugins/clickOutsideDirective.js";
const app = createApp(App);
//app.use(unleashPlugin, { config });
app.use(auth0Plugin);
app.use(fetchWrapper);
app.use(uploadFilesPlugin);

const wsNetworkAdaptor = new FoliaBrowserWebsocketClientAdaptor(automergeUrl, app);

const repo = new Repo({
  network: [new BroadcastChannelNetworkAdapter(), wsNetworkAdaptor],
  storage: new IndexedDBStorageAdapter(),
});

Store.mutations.setAutomergeRepo(repo);
Store.mutations.setAutomergeNetworkAdaptor(wsNetworkAdaptor);

import { FoliaButtonWithTooltip } from "./views/common/web-components/folia-button-with-tooltip.js";
import { FoliaDropDownWithTooltip } from "./views/common/web-components/folia-drop-down-with-tooltip.js";
import { FoliaSimpleModal } from "./views/common/web-components/folia-simple-modal.js";

// try {
//   Sentry.init({
//     dsn: "https://40e68f03c5a46eff28552429a849eae1@o4506740817592320.ingest.sentry.io/4506740819755008",
//     integrations: [
//       Sentry.browserTracingIntegration(),
//       Sentry.replayIntegration({
//         maskAllText: false,
//         blockAllMedia: false,
//       }),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ["https://platform-api.folia.com", /^\/graphql\//],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
// } catch (error) {
//   console.log({ sentryError: error });
// }
app.use(VueGtag, {
  config: { id: "G-ZYK2KBX5CH" }
});
app.use(router);
app.directive('click-outside', ClickOutside);
app.mount("#app");