<template>
  <div
    class="document-card"
    :class="{ deleted: isDeleted }"
    @click.stop="openDocument"
  >
    <div class="document-preview bg-gray-100">
      <div v-if="!previewImage" class="document-card-icon loading" />
      <div v-else class="document-card-icon" :style="{ backgroundImage: previewImage }"/>
      <div class="document-preview-ext-badge" :class="{ [mimeTypeClassName]: true }">
        {{ mimeTypeTitle }}
      </div>
      <button v-if="canFavorite"
              class="toggle-favorite-btn"
              :class="{'is-favorite': doc?.isFavorite, 'is-not-favorite': !doc?.isFavorite}"
              @click.stop="$emit('toggleFavoriteStatus', doc.id, !doc?.isFavorite)"
      ></button>
      <FoliaContextMenu
        class="document-card-top-right-icon"
        :permissionsDocuments="permissions"
        :documentId="documentId"
        :userRole="userRole"
        :fileType="mimeTypeClassName"
        @menu-copy-document="$emit('copy-to-project', documentId)"
        @menu-document-details="$emit('show-document-details', documentId)"
        @menu-rename="$emit('rename', documentId)"
        @menu-delete-document="$emit('remove', documentId)"
        @download="(options)=>$emit('download', {documentId, fileName, ...options})"
      />
    </div>

    <div class="document-card-info">
      <div class="document-card-summary">
        <span class="document-name" :title="fileName">{{ fileName }}</span>
        <span class="document-last-modified" v-if="!projectName">Last Modified {{ lastEdited }}</span>
        <span class="project-name" v-if="projectName">{{projectName}}</span>
      </div>
      <div class="document-comments-status">
        <div class="status-icon" :class="{ 'unread': hasUnreadReplies }"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import {onMounted, computed, inject, watch, reactive, onBeforeUnmount, ref} from "vue";
import FoliaContextMenu from "../common/FoliaContextMenu.vue";
import { ICON_BY_TYPE } from "../common/constants.js";
import {formattedTime} from "../common/utils.js";
import ContextMenu from "../common/buttons/ContextMenuButton.vue";
import { BROADCAST_MESSAGE, PUBLIC_VIEWER, VIEWER } from "../../core/constants.js";
import { previewsStore } from "../../store/document-previews-store.js";

export default {
  name: "DocumentCard",
  components: {
    ContextMenu, FoliaContextMenu,
  },
  props: {
    doc: Object,
    permissions: Array,
    projectName: String,
    projectId: String,
    userRole: String,
  },
  setup(props) {
    const $fetch = inject("$fetch");
    const router = useRouter();
    const route = useRoute();

    const userRole = computed(() => {
      return props.userRole
    })

    const mimeTypeClassName = computed(() => {
      return props.doc.origin && ICON_BY_TYPE[props.doc.origin.mimeType]
        ? ICON_BY_TYPE[props.doc.origin.mimeType].className
        : ICON_BY_TYPE.default.className;
    })
    const mimeTypeTitle = computed(() => {
      return props.doc.origin && ICON_BY_TYPE[props.doc.origin.mimeType]
        ? ICON_BY_TYPE[props.doc.origin.mimeType].title.toUpperCase()
        : ICON_BY_TYPE.default.title.toUpperCase();
    })
    const documentId = computed(() => {
      return props.doc.id;
    });
    const fileName = computed(() => {
      return props.doc.name;
    });
    const hasUnreadReplies = computed(() => {
      return props.doc.unreadCounts > 0;
    });
    const isDeleted = computed(() => {
      return props.doc.deleted;
    });
    const lastEdited = computed(() => {
      return formattedTime(props.doc.updatedAt)
    });
    const isFlatten = computed(() => {
      return props.userRole === VIEWER || props.userRole === PUBLIC_VIEWER;
    });
    const canFavorite = computed(() => {
      return props.userRole && props.userRole !== PUBLIC_VIEWER;
    });
    const previewImage = computed(() => {
      // return previewsStore.get(props.doc?.id)?.preview;
      return `url("${props.doc?.preview}")` 
    });
    const previewLoading = computed(() => {
      return Boolean(previewsStore.get(props.doc?.id)?.loading);
    });

    onMounted(() => {
      $fetch.on(BROADCAST_MESSAGE.DOCUMENT_UPDATED, onDocumentChanged);
      loadPreview(props.doc.id);
      loadUnreadStatuses(props.doc.id)
    });
    onBeforeUnmount(() => {
      $fetch.off(BROADCAST_MESSAGE.DOCUMENT_UPDATED, onDocumentChanged);
    });
    function onDocumentChanged(docId) {
      if (documentId.value !== docId)
      loadPreview(documentId.value);
      loadUnreadStatuses(documentId.value);
    }

    watch(() => props.doc.id, (docId, oldDocId) => {
      loadPreview(docId);
      loadUnreadStatuses(documentId.value);
    });

    function loadPreview(docId) {
      if (previewsStore.get(docId)?.base64preview) return;
      previewsStore.set(docId, { base64preview: null, loading: true });
      // $fetch.getDocumentPreview(docId)
      //   .then(base64preview => {
      //     if (base64preview) previewsStore.set(docId, {
      //       base64preview: `url("${base64preview}")`,
      //       loading: false
      //     })
      //   })
      //   .catch(error => console.error(error));
    }
    function loadUnreadStatuses(documentId) {
    }

    function openDocument() {
      if (isDeleted.value) return;
      if (route.name === 'global-search') {
        router.push(`/p/${props.projectId}/d/${props.doc.id}`);
      } else {
        const path = route.path + "/d/" + props.doc.id;
        router.push(path);
      }
    }

    return {
      isFlatten, canFavorite, documentId, fileName, isDeleted,
      previewImage, previewLoading, lastEdited, hasUnreadReplies,
      mimeTypeClassName, mimeTypeTitle, userRole,

      openDocument,
    };
  },
};
</script>

<style lang="scss" scoped>
.document-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  padding: 5px;
  cursor: pointer;
  border: 1px solid #E2E8F0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  &:hover {
    box-shadow: 0 0 8px 4px #00000010;
  }
  &.deleted {
    opacity: 0.3;
  }

  .document-card-rename {
    top: 25px;
  }
  .document-card-delete {
    top: 5px;
  }
  .document-card-rename,
  .document-card-delete {
    position: absolute;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    color: silver;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  .document-preview {
    width: 100%;
    display: flex;
    border-radius: 4px 4px 0 0;
    padding: 8px;
    justify-content: center;

    .document-card-top-right-icon {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    .toggle-favorite-btn {
      position: absolute;
      left: 12px;
      top: 12px;
      width: 34px;
      height: 34px;
      border-radius: 6px;
      border: none;
      outline: none;

      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background-color: #F1F1F1!important;
      }

      &:active {
        transform: translate(1px, 1px);
      }

      &.is-favorite {
        background: url("../../assets/images/filled-start-2.svg") center no-repeat;
        background-size: 16px;
      }
      &.is-not-favorite {
        background: url("../../assets/images/outline-star.svg") center no-repeat;
        background-size: 16px;
      }
    }

    .document-card-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #f57849;
      width: 140px;
      height: 190px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      &.no-preview {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ctitle%3Efile-document-outline%3C/title%3E%3Cpath d='M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z' /%3E%3C/svg%3E");
      }
      &.loading {
        background: url("../../assets/images/loading-icon.gif") center no-repeat;
        background-size: 15px;
      }
    }
    .document-preview-ext-badge {
      position: absolute;
      display: flex;
      width: 50px;
      height: 20px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: center;
      left: 5px;
      bottom:72px;
      color: #FFFFFF;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      border-radius: 0 4px 0 0;

      &.pdf {
        background: #EF4444;
      }
      &.png, &.jpg {
        background: #9d06f5;
      }
      &.doc, &.docx, &.gdoc {
        background: #3B82F6;
      }
      &.xls, &.xlsx, &.gsheet {
        background: #10B981;
      }
      &.ppt, &.pptx, &.gslide {
        background: #F97316;
      }
    }
  }
  .document-card-info {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: 6px;

    height: 68px;
    min-height: 68px;
    max-height: 68px;
    width: 100%;
    padding: 16px;

    background-color: white;
    text-align: start;
    border-top: 1px solid #e2e8f0;
    border-radius: 0 0 8px 0;
    white-space: nowrap;
    overflow: hidden;

    .document-card-summary {
      display: flex;
      flex-direction: column;
      font-family: Inter, sans-serif;
      width: 90%;

      .document-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: #334155;
      }

      .document-last-modified, .project-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #64748B;
      }
    }

    .document-comments-status {
      display: flex;
      align-items: flex-end;
      width: 24px;
      height: 24px;

      .status-icon {
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 10H16M8 14H12M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 13.821 2.487 15.53 3.338 17L2.5 21.5L7 20.662C8.51954 21.5411 10.2445 22.0027 12 22Z' stroke='%23CBD5E1' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;

        &.unread {
          background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.67188 10H16.6719M8.67188 14H12.6719M12.6719 22C18.1949 22 22.6719 17.523 22.6719 12C22.6719 6.477 18.1949 2 12.6719 2C7.14888 2 2.67188 6.477 2.67188 12C2.67188 13.821 3.15888 15.53 4.00988 17L3.17188 21.5L7.67188 20.662C9.19142 21.5411 10.9164 22.0027 12.6719 22Z' stroke='%2394A3B8' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='19.6719' cy='5' r='3.5' fill='%232990FF' stroke='white'/%3E%3C/svg%3E%0A");
        }
      }
    }
  }
}
</style>
