<template>
  <div class="welcome-container">
    <div class="welcome-content">
      <div class="logo" @click="goSite"/>
      <div class="text-container">
        <span class="title">Welcome to Folia</span>
<!--        <span class="sub-text" v-if="emailConfirmed">-->
<!--          <p>Enter your name to continue creating profile</p>-->
<!--        </span>-->
<!--        <span class="sub-text" v-else>-->
<!--          <p>You’re almost there. We sent a verification email to [ EMAIL ADDRESS ]. Don’t see it? Make sure to check your spam folder.</p>-->
<!--        </span>-->
      </div>

      <section v-if="!emailConfirmed" class="welcome-section">
        <p class="welcome-text">You’re almost there. We sent a verification email to <b>{{email}}</b>. Don’t see it? Make sure to check your spam folder.</p>
        <div class="text-button" @click="resendEmail">
          <span>Resend email</span>
        </div>
        <div class="text-button" @click="signOut">
          <span>Return to login screen</span>
        </div>
      </section>
      <section v-else class="welcome-section">
        <div class="input-container">
          <div class="field-label">Enter your name</div>
          <input class="input-field" placeholder="User name" v-model="name" />
        </div>
        <ActionButton
          :disabled="errorValidation"
          :width="'100%'"
          :height="'36px'"
          :background-color="'#334155'"
          :fontColor="'#FFFFFF'"
          :text="'Continue'"
          @click="saveUserName"
        />
        <div class="text-button" @click="signOut">
          <span>Return to login screen</span>
        </div>
      </section>

    </div>
  </div>
</template>

<script>
import ActionButton from "../common/ActionButton.vue";
import { computed, inject, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { BROADCAST_MESSAGE, ERROR_TOAST, INFO_TOAST } from "../../core/constants.js";
export default {
  name: "Welcome",
  components: {
    ActionButton,
  },
  setup() {
    const router = useRouter();
    const $auth0 = inject("$auth0");
    const $fetch = inject("$fetch");
    const name = ref("");
    const buttonOk = ref(false);
    const email = ref("");
    const emailConfirmed = ref(false);

    onMounted(() => {
      $auth0.getUser()
        .then(user => {
          document.querySelectorAll("#splash-screen").forEach(el => el.remove());
          email.value = user?.profile.email;
          emailConfirmed.value = user?.profile.email_verified;
        });
    })
    const isNameEmpty = computed(() => name.value.trim() === '');

    const errorValidation = computed(() => {
      return !name.value || name.value.length === 0 || isNameEmpty.value;
    })
    function saveUserName() {
      if (!name.value) return
      $fetch.updateUserName(name.value)
        .then(() => router.replace("/"))
        .catch(error => {
          alert(error.message);
          return $auth0.signIn();
        });
    }

    function resendEmail() {
      $fetch.resendVerificationEmail(email.value)
        .then(() => $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
          type: INFO_TOAST,
          message: "Email sent"
        }))
        .catch(() => $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
          type: ERROR_TOAST,
          message: "Folia encountered an error when attempting to send the request. Please try again.",
        })
        );
    }

    function signOut() {
      $auth0.signOut().catch(console.error);
    }

    function goSite() {
      window.open("https://folia.com", "_blank");
    }

    return {
      email, name, emailConfirmed, buttonOk,
      errorValidation,
      saveUserName, resendEmail, signOut, goSite,
    }
  }
};
</script>

<style lang="scss" scoped>
  .welcome-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;

    .welcome-content {
      width: 300px;
      margin: 35px auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .welcome-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .welcome-text {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
        }

        .text-button {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          margin-top: 25px;
          color: #2990ff;
          cursor: pointer;
        }

        .input-container {
          width: 100%;
        }

        & > .input-container {
          margin-top: 15px;
        }

        & > .input-container ~ .input-container {
          margin-top: 20px;
        }

        .field-label {
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #64748B;
        }

        .input-field {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          background: #F1F5F9;
          width: 100% !important;
          height: 44px;
          margin-top: 8px;
          margin-bottom: 20px;
          box-sizing: border-box;
          padding: 0 6px;
          border: 1px solid #e2e8f0;
          border-radius: 6px;
        }
      }

      .logo {
        width: 99.87px;
        height: 56px;
        background-image: url('../../assets/images/logo-auth.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        margin-bottom: 35px;
        cursor: pointer;
      }

      .text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 24px;

        .title {
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  }
</style>
